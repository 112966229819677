import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import { core } from './store/store'
import { dashboard } from './components/dashboard/store/store'
import { assignments } from './components/assignments/store/store'
import { schedules } from './components/schedules/store/store'
import { meetings } from './components/schedules/meetings/store/store'

import { settings } from './components/settings/store/store'
import { account } from './components/settings/credits/store/store'
import { messaging } from './components/settings/messaging/store/store'
import { qualifications } from './components/settings/qualifications/store/store'
import { away } from './components/settings/away/store/store'
import { publicTalks } from './components/settings/public-talks/store/store'
import { publicScheduler } from './components/settings/public/store/store'
import { maps } from './components/settings/maps/store/store'
import { assignMaps } from './components/settings/assign-maps/store/store'
import { attendants } from './components/settings/attendants/store/store'
import { locations } from './components/settings/locations/store/store'
import { specialServiceGroups } from './components/settings/special-service-groups/store/store'
import { rotationGroups } from './components/settings/rotation-groups/store/store'
import { attendance } from './components/settings/attendance/store/store'
import { speakerDirectory } from './components/settings/speaker-directory/store/store'

import { tuxedo } from 'tuxedo-api-interface/src/module/tuxedo'
import { state } from './state'
import * as mutations from './mutations'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localForage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => (true)
})

export const getters = {
  refId (objectArray, link, id) {
    for (let i = 0; i < objectArray.length; i++) {
      if (objectArray[i][link] === id) {
        return i
      }
    }
  }
}

export const store = new Vuex.Store({
  getters,
  mutations,
  state: state,
  plugins: [vuexLocalStorage.plugin],
  modules: {
    tuxedo,
    core,
    account,
    dashboard,
    assignments,
    schedules,
    publicScheduler,
    settings,
    qualifications,
    meetings,
    away,
    publicTalks,
    maps,
    assignMaps,
    messaging,
    attendants,
    locations,
    specialServiceGroups,
    attendance,
    speakerDirectory,
    rotationGroups
  }
})

export default store
