import axios from 'axios'
import submit from '../../../router/submit'

export const GET_CONG_SETTINGS = ({ commit, getters }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/congregation')
      .then(function (response) {
        commit('UPDATE_CONG', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_CONG_SETTINGS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/congregation', payload.cong)
      .then(function (response) {
        commit('UPDATE_CONG', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        commit('SUBMIT_ERROR', error.data)
        reject(error)
      })
  })
}

export const GENERATE_ATTENDANT_SCHEDULE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/generate-attendant-schedule', payload.cong, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
      .then(function (response) {
        const filename = response.headers['content-disposition'].split('=')

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = filename[1]
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GENERATE_CLM_SCHEDULE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/generate-meeting-schedule', payload, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
      .then(function (response) {
        const filename = response.headers['content-disposition'].split('=')

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = filename[1]
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GENERATE_PUBLIC_SCHEDULE = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.post('api/generate-public-meeting-schedule', [], {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
      .then(function (response) {
        const filename = response.headers['content-disposition'].split('=')

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = filename[1]
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GENERATE_GROUP_LIST = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/generate-group-list', payload, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
      .then(function (response) {
        const filename = response.headers['content-disposition'].split('=')

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = filename[1]
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GENERATE_PUBLISHER_REPORTS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/reports/publisher-reports', {
      timeout: 5000
    })
  })
}

export const DOWNLOAD_PDF = ({ commit }, url) => {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
      .then(function (response) {
        const filename = response.headers['content-disposition'].split('=')

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = filename[1].replace(/"/g, '')
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DOWNLOAD_XLSX = ({ commit }, url) => {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
      .then(function (response) {
        const filename = response.headers['content-disposition'].split('=')

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = filename[1].replace(/"/g, '')
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GENERATE_TERRITORY_RECORDS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/reports/s13', {
      timeout: 5000
    })
  })
}

export const GET_TIMEZONES = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/time-zones')
      .then(function (response) {
        commit('UPDATE_TIMEZONES', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_PUBLISHER = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/user', payload.user)
      .then(function (response) {
        commit('UPDATE_PUB', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        commit('SUBMIT_ERROR', error.response.data)
        reject(error)
      })
  })
}

export const SEND_INVITE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    commit('submitting', true)
    submit.post('api/send-user-invite', payload)
      .then((res) => {
        commit('submitting', false)
        resolve(res)
      })
      .catch((err) => {
        commit('submitting', false)
        reject(err)
      })
  })
}
