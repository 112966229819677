<template>
  <router-link to="/settings/reports/" class="notification is-link text-center" v-if="on">
    <strong>{{ $t('notifications.report', {month:this.$root.lastMonth().toUpperCase()}) }}</strong>
  </router-link>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ServiceReportPrompt',
  computed: {
    ...mapState({
      modules: state => state.settings.cong.modules,
      alerts: state => state.dashboard.alerts,
      profile: state => state.profile
    }),
    on () {
      return this.modules &&
        this.modules.field_service_reports &&
        this.modules.field_service_reports.on &&
        !this.alerts.report &&
        this.profile.appointment !== 'guest' &&
        this.profile.appointment !== 'student'
    }
  }
}
</script>
