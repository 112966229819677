<template>

    <view-content :back="{ name:'Qualifications' }">

      <template v-slot:header>
        Part: {{ part ? part.part : '' }}
      </template>

      <template v-slot:actions>
        <action :primary="true" @click="assignFormActive = true">
          Add Publisher
        </action>
      </template>

        <table class="table is-fullwidth align-middle">
            <tr v-for="(user, key) in qualifiedPubs" :key="'qp' + key">
                <td>
                    <span class="last-name">{{ user.last_name }}, </span>
                    <span class="first-name">{{ user.first_name }}</span>
                    <span v-if="user.appointment === 'elder'"><i>(Elder)</i></span>
                    <span v-if="user.appointment === 'servant'"><i>(Servant)</i></span>
                </td>
                <td class="tools t1">
                    <button @click="promptRemovePub(user)" class="btn btn-danger btn-round">
                        <fa-icon :icon="['fal', 'trash']" />
                    </button>
                </td>
            </tr>
        </table>

      <portal to="modals">
        <MeetingPartQualificationEditForm
          :active="assignFormActive"
          :part="$route.params.id"
          @close="assignFormActive = false"
        />

        <delete
                :submitting="submitting"
                :header="'Remove Publisher'"
                :active.sync="activePromptRemovePub"
                :message="'Are you sure you wish to remove '+user.first_name+' '+user.last_name+' from this list?'"
                @submit="confirmRemovePub()"
        ></delete>
      </portal>

    </view-content>

</template>

<script>
import { mapState } from 'vuex'
import { get } from '@/mixins/api'
import { assign } from '@/mixins/storeMutations'
import MeetingPartQualificationEditForm from '../../forms/MeetingPartQualificationEditForm.vue'

export default {
  name: 'Qualification',
  components: { MeetingPartQualificationEditForm },
  mixins: [get, assign],
  data () {
    return {
      user: {
        first_name: null,
        last_name: null
      },
      submitting: false,
      assignFormActive: false,
      activePromptRemovePub: false
    }
  },
  created () {
    this.$emit('update:loading', true)
    this.$store.dispatch('GET_PART', { token: this.$store.state.core.token, part: this.$route.params.id }).then(() => {
      this.$emit('update:loading', false)
    })
  },
  computed: {
    ...mapState({
      users: state => state.users,
      part: state => state.qualifications.part
    }),
    qualifiedPubs () {
      let users = this._.orderBy(this.users, ['last_first'])
      users = users.filter(user => {
        if (this._.findIndex(user.qualified_parts, (part) => { return part.id === this.part.id }) >= 0) {
          return user
        }
      })
      return users
    }
  },
  methods: {
    promptRemovePub (user) {
      this.user = user
      this.part.user = [user.id]
      this.activePromptRemovePub = true
    },
    confirmRemovePub () {
      this.submitting = true
      this.$store.dispatch('REMOVE_QUALIFICATION', {
        token: this.$store.state.core.token,
        qualification: this.part
      }).then(() => {
        this.submitting = false
        this.activePromptRemovePub = false
      })
    }
  }
}

</script>
