<template>
  <div>
    <modal :active="active" :header="'Send Invite'" :save="'Send'" @submit="submit" :validated="inviteOption != null">
      <div v-if="resource.active">
        <div class="field">
          <div class="detail-radio">
            <small class="text-warning" v-if="!resource.phone">Option Not Available - This publisher doesn't have a registered phone number</small>
            <label class="card is-blue" :class="!resource.phone ? 'disabled' : ''">
              <input type="radio" :value="'sms'" v-model="inviteOption" :disabled="!resource.phone">
              <span class="card-content">
                  <span class="number-header">
                      <span class="num"><fa-icon :icon="['fal', 'signal-stream']" /></span>
                      <span class="header">
                        Direct SMS Invite <small>(Cost 10c)</small>
                      </span>
                  </span>
                  <p style="text-transform:initial">Send an invite via SMS, because this option uses a third-party service (for reliability) it charges your congregation account 10c each time an invite is sent.</p>
                </span>
            </label>
            <small class="text-warning" v-if="!validateEmail(resource.email)">Option Not Available - This publisher doesn't have a registered email address</small>
            <label class="card is-blue" :class="!validateEmail(resource.email) ? 'disabled' : ''">
              <input type="radio" :value="'email'" v-model="inviteOption" :disabled="!validateEmail(resource.email)">
              <span class="card-content">
                  <span class="number-header">
                      <span class="num"><fa-icon :icon="['fal', 'envelope']" /></span>
                      <span class="header">
                        Email Invite
                      </span>
                  </span>
                  <p style="text-transform:initial">Send an invite via Email at no cost, in order for this to work the publisher will need to have a valid email address registered in the Service App.</p>
                </span>
            </label>
            <label class="card is-blue">
              <input type="radio" :value="'legacy-sms'" v-model="inviteOption">
              <span class="card-content">
                  <span class="number-header">
                      <span class="num"><fa-icon :icon="['fal', 'sms']" /></span>
                      <span class="header">
                        Legacy SMS Invite
                      </span>
                  </span>
                  <p style="text-transform:initial">Attempt to send an invite for free using your devices own messaging service, depending on your device there is a chance this method will fail.</p>
                </span>
            </label>
          </div>
        </div>
        <input-toggle :label="'Reset Publisher Access Token'" v-model="inviteReset" :options="{0:'No',1:'Yes'}" :help="'Turning this on will reset this publishers access token on confirmation, any previous installations of the Service App on any of their devices will no longer authenticate.'" />
      </div>
      <div v-else>
        <p>You will need to make this publishers profile active before you can send them an invite. You can do so by editing them and turning the 'Active' toggle on.</p>
      </div>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { store } from '@/store'
import { validateEmail } from '@/helpers'

const props = defineProps(['user', 'active'])
const emit = defineEmits(['close'])

const initialResource = {
  id: 0,
  first_name: null,
  last_name: null,
  email: '',
  phone: '',
  lang: null,
  gender: null,
  active: null
}
const resource = ref({ ...initialResource })
const inviteOption = ref(null)
const inviteReset = ref(0)

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    if (props.user) {
      Object.keys(resource.value).forEach(key => {
        if (props.user[key]) resource.value[key] = props.user[key]
      })
    }
  }
})

const submit = () => {
  store.dispatch('SEND_INVITE', { user: resource.value.id, method: inviteOption.value, reset: inviteReset.value })
    .then(response => {
      if (response.data.message) {
        document.getElementById(resource.value.id + '-renew').href = 'sms:' + resource.value.phone + ';?&body=' + response.data.message
        document.getElementById(resource.value.id + '-renew').click()
      }
      emit('close')
    })
}

</script>
