<template>
  <view-content :back="{name:'Publishers'}">
    <template v-slot:header>
      {{ user.last_first }}
    </template>

    <div class="grid gap-4">
      <ShadowCardRounded>
        <div class="text-base font-bold">{{ user.last_first }}</div>
        <div class="text-base capitalize">{{ user.appointment }}</div>

        <div class="grid md:grid-cols-2 gap-2 md:gap-4 mt-2">
          <LabeledData :label="$t('fields.email.label')">
            {{ user.email }}
          </LabeledData>
          <LabeledData :label="$t('fields.mobile.label')">
            {{ user.phone }}
          </LabeledData>
        </div>
        <div class="grid md:grid-cols-3 gap-2 md:gap-4">
          <LabeledData :label="$t('fields.group.label')">
            {{ user.group_name }}
          </LabeledData>
          <LabeledData :label="$t('fields.address.label')" class="md:col-span-2">
            {{ user.address }}
          </LabeledData>
        </div>
        <LabeledData :label="$t('labels.last_login')" class="md:col-span-2">
          {{ new Sec(user.last_login).format('F jS, Y') }}
        </LabeledData>
      </ShadowCardRounded>
    </div>

    <Accordion>
      <AccordionItem :headerClass="'bg-teal-800 text-white'" v-if="permits['settings.users.qualifications'] && user.gender === 1">
        <template v-slot:header>
          <b class="uppercase">{{ $t('nav.qualifications.label') }}</b><br>
          <small>
            {{ $t('resources.count', {count: intersection(qualifications, [1]).length, resource: $tc('resources.qualification', intersection(qualifications, [1]).length) }) }}
          </small>
        </template>
        <div class="grid divide-y gap-2">
          <input-toggle
            :label="$t('labels.meetings.prayer')"
            :ifTrue="'yes'"
            :ifFalse="'no'"
            :value="qualifications.includes(1)"
            @input="sync(1)"
          />
        </div>
      </AccordionItem>
      <AccordionItem :headerClass="'bg-teal-800 text-white'" v-if="permits['settings.users.qualifications'] && user.gender === 1">
        <template v-slot:header>
          <b class="uppercase">{{ $t('labels.type.clam') }}</b><br>
          <small>
            {{ $t('resources.count', {count: intersection(qualifications, [2,17,3,4,10,11,12]).length, resource: $tc('resources.qualification', intersection(qualifications, [2,17,3,4,10,11,12]).length) }) }}
          </small>
        </template>
        <div v-if="user.gender === 1">
          <div class="grid divide-y gap-2">
            <input-toggle
              v-if="['elder'].includes(user.appointment)"
              :label="$t('labels.meetings.chairman')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(2)"
              @input="sync(2)"
            />
            <input-toggle
              v-if="['elder', 'servant'].includes(user.appointment)"
              class="pt-2"
              :label="$t('labels.meetings.aux_counselor')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(17)"
              @input="sync(17)"
            />
            <input-toggle
              v-if="['elder', 'servant'].includes(user.appointment)"
              class="pt-2"
              :label="$t('labels.meetings.treasures_talk')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(3)"
              @input="sync(3)"
            />
            <input-toggle
              v-if="['elder', 'servant'].includes(user.appointment)"
              class="pt-2"
              :label="$t('labels.meetings.digging_for_gems')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(4)"
              @input="sync(4)"
            />
            <input-toggle
              v-if="['elder', 'servant'].includes(user.appointment)"
              class="pt-2"
              :label="$t('labels.meetings.living_as_christians')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(10)"
              @input="sync(10)"
            />
            <input-toggle
              v-if="['elder', 'servant'].includes(user.appointment)"
              class="pt-2"
              :label="$t('labels.meetings.study_conductor')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(11)"
              @input="sync(11)"
            />
            <input-toggle
              class="pt-2"
              :label="$t('labels.meetings.bible_study_reader')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(12)"
              @input="sync(12)"
            />
          </div>
        </div>
      </AccordionItem>
      <AccordionItem :headerClass="'bg-teal-800 text-white'" v-if="permits['settings.users.qualifications']">
        <template v-slot:header>
          <b class="uppercase">{{ $t('labels.meetings.student_assignments') }}</b><br>
          <small>
            {{ $t('resources.count', {count: intersection(qualifications, [5,6,18,8,9]).length, resource: $tc('resources.qualification', intersection(qualifications, [5,6,18,8,9]).length) }) }}
          </small>
        </template>
        <div>
          <div class="grid divide-y gap-2">
            <input-toggle
              v-if="user.gender === 1"
              :label="$t('labels.meetings.bible_reading')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(5)"
              @input="sync(5)"
            />
            <input-toggle
              class="pt-2"
              :label="$t('labels.meetings.call_demonstration')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(6)"
              @input="sync(6)"
            />
            <input-toggle
              class="pt-2"
              :label="$t('labels.meetings.householder')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(18)"
              @input="sync(18)"
            />
            <input-toggle
              class="pt-2"
              :label="$t('labels.meetings.bible_study')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(8)"
              @input="sync(8)"
            />
            <input-toggle
              v-if="user.gender === 1"
              class="pt-2"
              :label="$t('labels.meetings.student_talk')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(9)"
              @input="sync(9)"
            />
          </div>
        </div>
      </AccordionItem>
      <AccordionItem :headerClass="'bg-teal-800 text-white'" v-if="permits['settings.users.qualifications']  && user.gender === 1">
        <template v-slot:header>
          <b class="uppercase">{{ $t('labels.type.public') }}</b><br>
          <small>
            {{ $t('resources.count', {count: intersection(qualifications, [16,13,14,15]).length, resource: $tc('resources.qualification', intersection(qualifications, [16,13,14,15]).length) }) }}
          </small>
        </template>
        <div v-if="user.gender === 1">
          <div class="grid divide-y gap-2">
            <input-toggle
              :label="$t('labels.meetings.public_meeting_chairman')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(16)"
              @input="sync(16)"
            />
            <input-toggle
              class="pt-2"
              :label="$t('labels.meetings.watchtower_reader')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(13)"
              @input="sync(13)"
            />
            <input-toggle
              v-if="['elder', 'servant'].includes(user.appointment)"
              class="pt-2"
              :label="$t('labels.meetings.local_public_talk')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(14)"
              @input="sync(14)"
            />
            <input-toggle
              v-if="['elder', 'servant'].includes(user.appointment)"
              class="pt-2"
              :label="$t('labels.meetings.away_public_talk')"
              :ifTrue="'yes'"
              :ifFalse="'no'"
              :value="qualifications.includes(15)"
              @input="sync(15)"
            />
          </div>
        </div>
      </AccordionItem>
      <AccordionItem :headerClass="'bg-teal-800 text-white'">
        <template v-slot:header>
          <b class="uppercase">{{ $tc('resources.attendant_responsibility', 0) }}</b><br>
          <small>
            {{ $t('resources.count', {count: reject(responsibilities, r => !r.attendants.includes(user.id)).length, resource: $tc('resources.responsibility', reject(responsibilities, r => !r.attendants.includes(user.id)).length) }) }}
          </small>
        </template>
        <div class="grid gap-4">
          <div class="grid divide-y gap-2">
            <div class="pt-2 first:pt-0" v-for="att in responsibilities" :key="'a' + att.id">
              <input-toggle
                :label="att.label"
                :ifTrue="'yes'"
                :ifFalse="'no'"
                :value="att.attendants.includes(user.id)"
                @input="syncResponsibility(att.id)"
              />
            </div>
          </div>
        </div>

      </AccordionItem>
      <AccordionItem :headerClass="'bg-teal-800 text-white'">
        <template v-slot:header>
          <b class="uppercase">{{ $t('labels.cart_partners.label') }}</b><br>
          <small>
            {{ $t('resources.count', {count: partners.length, resource: $tc('resources.cart_partner', partners.length) }) }}
          </small>
        </template>
        <div class="grid gap-4">
          <div class="grid divide-y gap-2">
            <div class="pt-2 first:pt-0" v-for="u in users" :key="'u' + u.id">
              <input-toggle
                :label="u.last_first"
                :ifTrue="'yes'"
                :ifFalse="'no'"
                :value="partners.includes(u.id)"
                @input="syncPartner(u.id)"
              />
            </div>
          </div>
        </div>

      </AccordionItem>
      <AccordionItem :headerClass="'bg-teal-800 text-white'">
        <template v-slot:header>
          <b class="uppercase">{{ $tc('resources.notification', 0) }}</b><br>
          <small>
            {{ $t('resources.count', {count: notifications.length, resource: $tc('resources.notification', notifications.length) }) }}
          </small>
        </template>
        <div class="flex flex-col gap-2">
          <div class="pt-2" v-for="note in notifications" :key="'n' + note.id">
            <div class="bg-gray-100 p-2 rounded">
              <div class="font-bold pb-1">{{ $t('notifications.'+note.type) }}</div>
              <div class="break-words w-full">{{ note.data.content }}</div>
            </div>
            <div class="text-right text-xs">{{ new Sec(note.created_at).format('F jS, Y') }}</div>
          </div>
        </div>
      </AccordionItem>
    </Accordion>

  </view-content>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { store } from '@/store'
import { get } from '@/composables/serverInterface'
import { assign, merge } from '@/composables/dataInterface'
import { find, findIndex, intersection, map, orderBy, reject, uniq } from 'lodash'
import { Sec } from '@/mixins/sec'
import ShadowCardRounded from '@/components/elements/ShadowCardRounded.vue'
import LabeledData from '@/components/elements/LabeledData.vue'
import Accordion from '@/components/elements/Accordion.vue'
import AccordionItem from '@/components/elements/AccordionItem.vue'

const props = defineProps(['id'])

const qualifications = ref([])
const partners = ref([])
const notifications = ref([])

const users = computed(() => orderBy(store.state.users, 'last_first'))
const user = computed(() => {
  return find(store.state.users, u => parseInt(u.id) === parseInt(props.id))
})
const responsibilities = computed(() => orderBy(store.state.attendantResponsibilities, 'order'))
const permits = computed(() => store.state.core.permits)

onMounted(() => {
  get('attendant-responsibilities').then(res => {
    assign('attendantResponsibilities', res)
  })

  get(['user', props.id, 'cart-partners']).then(res => {
    partners.value = res.data
  })
  get(['user', props.id, 'notifications']).then(res => {
    notifications.value = res.data
  })
})

watch(user, u => {
  qualifications.value = uniq(map(u.qualified_parts, p => p.id))
}, { immediate: true })

const sync = (id) => {
  const uri = ['user', user.value.id, 'part-qualification', id]
  const index = findIndex(qualifications.value, q => parseInt(q) === parseInt(id))
  if (index > -1) {
    uri.push('detach')
    qualifications.value.splice(index, 1)
  } else {
    uri.push('attach')
    qualifications.value.push(id)
  }
  get(uri).then(res => merge('users', res.data.data))
}

const syncResponsibility = (id) => {
  const uri = ['user', user.value.id, 'attendant-responsibility', id]
  const responsibility = find(responsibilities.value, r => parseInt(r.id) === parseInt(id))
  if (responsibility) {
    const index = findIndex(responsibility.attendants, a => parseInt(a) === parseInt(user.value.id))
    if (index > -1) {
      uri.push('detach')
      responsibility.attendants.splice(index, 1)
    } else {
      uri.push('attach')
      responsibility.attendants.push(user.value.id)
    }
    get(uri).then(res => merge('attendantResponsibilities', res.data.data))
  }
}

const syncPartner = (id) => {
  const uri = ['user', user.value.id, 'cart-partner', id]
  const index = findIndex(partners.value, p => parseInt(p) === parseInt(id))
  if (index > -1) {
    uri.push('detach')
    partners.value.splice(index, 1)
  } else {
    uri.push('attach')
    partners.value.push(id)
  }
  get(uri).then(res => {
    partners.value = res.data
  })
}

</script>
