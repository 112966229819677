<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      Rotation Groups
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="permits['settings.rotation_groups.add']" @click="addGroup">
        Add Rotation Group
      </action>
    </template>

    <table class="table is-fullwidth align-middle">
      <tr v-for="group in rotations" :key="'group' + group.id">
        <td>
          <span>{{ group.label }}</span><br>
          <span>{{ group.location }}</span><br>
          <small>Booked up to {{ date(group.current_date).format('l jS M, Y') }} </small>
        </td>
        <td class="tools t2">
          <button @click="editGroup(group)" class="btn btn-info btn-round mr-1" v-if="permits['settings.groups.edit']">
            <fa-icon :icon="['fal', 'pencil']" />
          </button>
          <button @click="promptDeleteGroup(group)" class="btn btn-danger btn-round" v-if="permits['settings.groups.delete']">
            <fa-icon :icon="['fal', 'trash']" />
          </button>
        </td>
      </tr>
    </table>

    <portal to="modals">
      <modal :submitting="submitting" :header="'Group Form'" :active.sync="groupFormActive" @submit="submitGroup()" :validated="groupValid">
        <template v-slot:default="{ error }">
          <tuxedo-input :inputClass="'bg-gray-100'" :label="'Name'" :type="'text'" v-model="group.label" :error="error" />
          <input-slider :label="'Frequency'" v-model="group.frequency" :options="frequency" />
          <input-slider class="pt-2" :label="'Week of the Month'" v-model="group.week_of_month" :options="weeks" v-if="group.frequency === 'month'" />
          <input-slider class="pt-2" :label="'Day of the Week'" v-model="group.day_of_week" :options="days" v-if="group.frequency" />
          <input-slider class="pt-2" :label="'Rotate Overseers'" v-model="group.rotation" :options="rotation" v-if="group.frequency === 'week'" />
          <input-time class="pt-2" :label="'Time'" :name="'time_of_day'" :minute-step="5" v-model="group.time_of_day" />
          <input-toggle class="mb-3" :label="'Offline/Online'" v-model="group.online" />
          <tuxedo-input :inputClass="'bg-gray-100'" v-if="group.online" :label="'Meeting URL'" :type="'text'" v-model="group.location" :error="error" />
          <tuxedo-input :inputClass="'bg-gray-100'" v-if="!group.online" :label="'Location Address'" :type="'text'" v-model="group.location" :error="error" />
          <tuxedo-input :inputClass="'bg-gray-100'" v-if="!group.online" :label="'Coordinates'" :type="'text'" v-model="group.coordinates" :error="error" :help="'Enter coordinates if you wish this group to contain a link to Google Maps.'" />
          <tuxedo-select :inputClass="'bg-gray-100'" :label="'Overseers'" :name="'overseers'"
                         v-model="group.overseers" :options="optionalPubs" :multi="true" :insistLabel="'last_first'"
                         :insistReturn="'id'" :help="'Select the brothers you wish to be included in this group rotation as assigned overseers.'"
                         :error="error" />
          <tuxedo-select :inputClass="'bg-gray-100'" :label="'Specific to Field Service Groups?'" :name="'groups'"
                         v-model="group.groups" :options="groupOptions" :multi="true" :insistLabel="'name'"
                         :insistReturn="'id'" :help="'Indicate which groups (if any) this rotation arrangement should only be applied to.'"
                         :error="error" />
          <tuxedo-select :inputClass="'bg-gray-100'" :label="'Replaces Standing Group?'" :name="'replaces'"
                         v-model="group.replaces" :options="replacesOptions" :multi="true"
                         :insistReturn="'id'" :help="'Indicate which current regular scheduled groups (if any) this rotation arrangement should replace.'"
                         :error="error">
            <template v-slot:label="{ option }">
              <span v-html="option.label"></span>
            </template>
          </tuxedo-select>
        </template>
      </modal>

      <delete
        :submitting="submitting"
        :header="'Delete Group'"
        :active.sync="groupDeleteActive"
        :message="'Are you sure you wish to delete '+group.name+' from the groups?'"
        @submit="confirmDeleteGroup()"
      ></delete>
    </portal>

  </view-content>

</template>

<script>
import { get } from '@/mixins/api'
import { assign } from '@/mixins/storeMutations'
import { resetData, fillObject } from '@/mixins/helpers'
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'

export default {
  name: 'RotationGroups',
  mixins: [get, assign, resetData, fillObject],
  data () {
    return {
      group: {
        id: null,
        label: null,
        overseers: [],
        rotation: null,
        frequency: null,
        online: null,
        day_of_week: null,
        week_of_month: null,
        time_of_day: null,
        location: null,
        coordinates: null,
        replaces: [],
        groups: []
      },
      rotation: {
        week: 'Every Week',
        fortnight: 'Every Two Weeks',
        month: 'Every Month'
      },
      frequency: {
        week: 'Every Week',
        month: 'Every Month'
      },
      days: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat'
      },
      weeks: {
        0: '1st Week',
        1: '2nd Week',
        2: '3rd Week',
        3: '4th Week',
        4: 'Last Week'
      },
      groupFormActive: false,
      groupDeleteActive: false,
      submitting: false,
      loading: true,
      error: false
    }
  },
  computed: {
    ...mapState({
      permits: state => state.core.permits,
      users: state => state.users,
      rotations: state => state.rotationGroups.rotations,
      groups: state => state.groups,
      fsgs: state => state.fieldServiceGroups
    }),
    groupValid () {
      if (
        this.group.label &&
        this.group.frequency &&
        this.group.overseers.length > 0 &&
        this.group.location &&
        this.group.time_of_day
      ) return true
      return false
    },
    groupOptions () {
      return this._.orderBy(this.groups, ['name'])
    },
    replacesOptions () {
      return this._.orderBy(this.fsgs, ['meeting_day'])
    },
    optionalPubs () {
      let users = this._.orderBy(this.users, ['last_first'])
      users = users.filter(user => {
        if (parseInt(user.active) === 0) return false
        // Requires a brother (not cleared out by the following rules)
        if (parseInt(user.gender) === 2) return false
        // Requiring a baptised publisher
        if ((!['elder', 'servant', 'publisher'].includes(user.appointment))) return false
        return user
      })
      return users
    }
  },
  created () {
    this.get('groups').then(res => {
      this.assign('groups', res)
    })
    this.get('field-service-groups').then(res => {
      this.assign('fieldServiceGroups', res)
    })
    this.$store.dispatch('GET_ROTATION_GROUPS').then(() => {
      this.$store.commit('BACKGROUND_LOADING', false)
    })
  },
  methods: {
    date (time) {
      return new Sec(time)
    },
    addGroup () {
      this.resetData('group')
      this.groupFormActive = true
    },
    editGroup (group) {
      this.resetData('group')
      this.fillObject(this.group, group, 0)
      this.groupFormActive = true
    },
    submitGroup () {
      this.submitting = true
      this.$store.dispatch('POST_ROTATION_GROUP', this.group).then(() => {
        this.submitting = false
        this.groupFormActive = false
      })
    },
    promptDeleteGroup (group) {
      this.$root.copyData(group, this.group)
      this.groupDeleteActive = true
    },
    confirmDeleteGroup () {
      this.submitting = true
      this.$store.dispatch('DELETE_ROTATION_GROUP', this.group).then(() => {
        this.submitting = false
        this.groupDeleteActive = false
      })
    }
  }
}
</script>
