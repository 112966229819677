<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      {{ $tc('resources.cart_booking', 0) }}
    </template>

    <table class="table is-fullwidth align-middle">
      <tr v-for="booking in resources" :key="'bookings' + booking.id">
        <td>
          <span>{{ find(cartLocations, 'id', booking.cart_location_id, 'location') }}</span><br>
          <date-time :date="booking.start_at" /><br>
          <small>{{ bookingUsersString(booking) }}</small>
        </td>
        <td class="tools t1">
          <button @click="promptDeleteBooking(booking)" class="btn btn-danger btn-round" v-if="permits['settings.cart_locations']">
            <fa-icon :icon="['fal', 'trash']" />
          </button>
        </td>
      </tr>
    </table>
    <div class="spinner" style="margin-top:15px" v-if="scrolledToBottom">
      <fa-icon :icon="['fal', 'spinner-third']" spin />
    </div>

    <delete
      :submitting="submitting"
      :header="'Delete Booking'"
      :active.sync="bookingDeleteActive"
      :message="'Are you sure you wish to delete this booking?'"
      @submit="confirmDeleteBooking()"
    ></delete>
  </view-content>

</template>

<script>
import { mapState } from 'vuex'
import { get, destroy } from '../../../mixins/api'
import { assign, push, forget } from '../../../mixins/storeMutations'

export default {
  name: 'CartBookings',
  mixins: [get, destroy, assign, push, forget],
  data () {
    return {
      activeID: null,
      bookingDeleteActive: false,
      submitting: null,
      loading: true,
      error: false,
      nextPage: 1,
      lastPage: 10000,
      scrolledToBottom: false
    }
  },
  computed: {
    ...mapState({
      resources: state => state.cartSchedules,
      cartLocations: state => state.cartLocations,
      users: state => state.users,
      permits: state => state.core.permits,
      sid: state => state.core.sid
    })
  },
  created () {
    this.get('cart-locations').then(res => {
      this.assign('cartLocations', res)
    })
    this.get('cart-schedules').then(res => {
      this.assign('cartSchedules', res)
      this.nextPage = res.data.meta.current_page + 1
    })
  },
  mounted () {
    this.scroll()
  },
  methods: {
    bookingUsersString (booking) {
      const users = []
      booking.users.forEach(u => {
        users.push(this.find(this.users, 'id', u, 'full_name'))
      })

      return users.join(', ')
    },
    promptDeleteBooking (booking) {
      this.activeID = booking.id
      this.bookingDeleteActive = true
    },
    confirmDeleteBooking () {
      this.destroy(['cart-schedules', this.activeID]).then(() => {
        this.forget('cartSchedules', this.activeID)
        this.bookingDeleteActive = false
      })
    },
    scroll () {
      window.onscroll = () => {
        const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight > document.documentElement.offsetHeight
        if (bottomOfWindow && !this.scrolledToBottom && this.nextPage <= this.lastPage) {
          this.scrolledToBottom = true
          this.get('cart-schedules', { params: { page: this.nextPage } }).then(res => {
            this.push('cartSchedules', res)
            this.nextPage = res.data.meta.current_page + 1
            this.lastPage = res.data.meta.last_page
            setTimeout(() => {
              this.scrolledToBottom = false
            }, 2000)
          })
        }
      }
    }
  }
}
</script>

<style>
.last-name {
  text-transform: uppercase;
}
</style>
