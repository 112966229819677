import axios from 'axios'

export const GET_SPECIAL_SERVICE_GROUPS = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/special-service-groups')
      .then(function (response) {
        commit('SET_SPECIAL_SERVICE_GROUPS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_SPECIAL_SERVICE_GROUP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/special-service-group', payload)
      .then(function (response) {
        commit('SET_SPECIAL_SERVICE_GROUPS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_SPECIAL_SERVICE_GROUP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/special-service-group', { data: payload })
      .then(function (response) {
        commit('DELETE_SPECIAL_SERVICE_GROUP', payload)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
