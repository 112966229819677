<template>

    <div id="new-spinner-background">

        <div class="container">

            <div class="top">
            </div>

            <div class="bottom">
                <div class="logo flex justify-center">
                    <img src="https://srvc.app/logo2x.png" />
                </div>

                <div class="text-animation">
                    <div class="auth" :class="$parent.authClass">
                        <p v-html="$t('labels.authenticate')"></p>
                    </div>
                </div>

                <div class="spinner">
                    <fa-icon :icon="['fal', 'spinner-third']" spin />
                </div>
            </div>
        </div>

    </div>

</template>

<script>

export default {
  name: 'Authenticate',
  data () {
    return {
    }
  },
  components: {

  },
  created () {
    this.$parent.authenticate()
  }
}
</script>
