<template>
  <div ref="cartPrompt" class="notification is-cart" :class="collapse ? 'collapsing' : ''" v-if="alerts.cart && nextCartActive">
    <button class="delete" @click="dismissAlert('nextCartActive')"></button>
    <div v-html="
            $t('notifications.next_cart', {
                location: location.location,
                date: date,
                time: time
            })"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'

export default {
  name: 'CartPrompt',
  data () {
    return {
      collapse: false,
      height: null
    }
  },
  mounted () {
    this.height = this.$refs.cartPrompt ? this.$refs.cartPrompt.clientHeight : 0
  },
  computed: {
    ...mapState({
      alerts: state => state.dashboard.alerts,
      nextCartActive: state => state.dashboard.nextCartActive,
      cartLocations: state => state.cartLocations
    }),
    location () {
      return this.cartLocations.find(l => parseInt(l.id) === parseInt(this.alerts.cart.cart_location_id))
    },
    date () {
      return new Sec(this.alerts.cart.start_at).format('l jS F')
    },
    time () {
      return new Sec(this.alerts.cart.start_at).format('g:ia')
    }
  },
  methods: {
    dismissAlert (alert) {
      this.collapse = true
      this.$refs.cartPrompt.style.height = this.height + 'px'
      setTimeout(() => {
        this.$refs.cartPrompt.style.height = 0
        this.$refs.cartPrompt.style.padding = 0
        this.$refs.cartPrompt.style.margin = 0
        setTimeout(() => {
          this.collapse = false
          this.$store.dispatch('HIDE_ALERT', { alert: alert })
        }, 300)
      }, 500)
    }
  }
}
</script>
