<template>

  <view-content :back="{ name:'Settings' }">

    <template v-slot:header>
      Dates Away
    </template>

    <template v-slot:actions>
      <action :primary="true" @click="addDates">
        Add Dates
      </action>
    </template>

    <div v-for="user in validPublishers" :key="'vp' + user.id">
      <div class="card is-blue" v-if="user.away_dates && user.away_dates.length > 0">
        <div class="card-content">
          <table class="table is-narrow is-fullwidth no-border">
            <tr>
              <th>{{ user.last_first }}</th>
            </tr>
            <tr v-for="away in user.away_dates" :key="'away' + away.id">
              <td>{{ date(away.start_date).format('M jS, Y') }} &mdash; {{ date(away.end_date).format('M jS, Y') }}
                <small>({{ date(away.end_date).diff(date(away.start_date), 'days') }} days)</small>
                <br>
                {{ away.comment }}
              </td>
              <td class="tools t1">
                <button @click="promptDeleteDates(away)" class="btn btn-danger btn-round">
                  <fa-icon :icon="['fal', 'trash']" />
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <portal to="modals">
    <modal :submitting="submitting" :header="'Away Date Form'" :active.sync="dateFormActive" @submit="submitDates()" :validated="datesValid">
      <tuxedo-select
        :label="'Publisher'"
        name="user_id"
        :options="validPublishers"
        v-model="away.user_id"
        insistLabel="last_first"
        inputClass="bg-gray-100"
        insistReturn="id"
      />
      <datefield :label="'Start Date'" v-model="away.start_date"></datefield>
      <datefield :label="'End Date'" v-model="away.end_date"></datefield>
      <input-text :label="'Comments'" v-model="away.comment" />
    </modal>

    <delete
      :submitting="submitting"
      :header="'Delete Dates'"
      :active.sync="dateDeleteActive"
      :message="'Are you sure you wish to delete '+date(away.start_date).format('M jS, Y')+' — '+date(away.end_date).format('M jS, Y')+'?'"
      @submit="confirmDeleteDates()"
    ></delete>
    </portal>
  </view-content>

</template>

<script>
import Delete from '../../general/confirm-delete.vue'
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'

export default {
  name: 'DatesAway',
  data () {
    return {
      away: {
        id: null,
        user_id: null,
        start_date: null,
        end_date: null,
        comment: null
      },
      dateFormActive: false,
      dateDeleteActive: false,
      submitting: null,
      loading: true,
      error: false
    }
  },
  computed: {
    ...mapState({
      users: state => state.users,
      permits: state => state.core.permits,
      sid: state => state.core.sid
    }),
    validPublishers () {
      let users = this._.orderBy(this.users, ['last_first'])
      if (!this.permits['settings.away.admin']) {
        users = users.filter((record) => {
          if (parseInt(record.id) === parseInt(this.sid)) return record
        })
      }
      return users
    },
    datesValid () {
      return true
    }
  },
  components: {
    Delete
  },
  created () {
  },
  methods: {
    date (time) {
      return new Sec(time)
    },
    addDates () {
      this.$root.clearData(this.away)
      this.dateFormActive = true
    },
    submitDates () {
      this.submitting = true
      this.$store.dispatch('ADD_AWAY_DATES', { token: this.$store.state.core.token, away: this.away }).then(() => {
        this.$emit('update:loading', false)
        this.submitting = false
        this.dateFormActive = false
      })
    },
    promptDeleteDates (away) {
      this.$root.copyData(away, this.away)
      this.dateDeleteActive = true
    },
    confirmDeleteDates () {
      this.submitting = true
      this.$store.dispatch('DELETE_AWAY_DATES', { token: this.$store.state.core.token, away: this.away }).then(() => {
        this.$emit('update:loading', false)
        this.submitting = false
        this.dateDeleteActive = false
      })
    }
  }
}
</script>

<style>
.last-name {
  text-transform: uppercase;
}
</style>
