<template>

    <view-content :back="{ name:'Schedules'} ">

      <template v-slot:header>
        {{ $t('labels.service.label') }}
      </template>

      <template v-slot:subtext>
        {{ $t('labels.service.subtext') }}
      </template>

        <div class="card is-blue" v-for="meeting in meetings" :key="'meeting' + meeting.id">
            <div class="card-content">
                <div class="date-header">
                    <div class="date">
                        <span>{{ meeting.meeting_day_number }}</span>
                        <span>{{ meeting.meeting_day_text }}</span>
                    </div>
                    <div class="header">
                        {{ meeting.label }}
                    </div>
                    <div class="direction-pin" v-if="meeting.google_location_url">
                        <a :href="meeting.google_location_url">
                            <fa-icon :icon="['fal', 'map-marker-alt']" />
                        </a>
                    </div>
                </div>
                <table class="table is-narrow is-fullwidth no-border">
                    <tr>
                        <th width="100px">Location:</th>
                        <td>{{ meeting.location }}</td>
                    </tr>
                    <tr>
                        <th>Time:</th>
                        <td>{{ meeting.time }}</td>
                    </tr>
                    <tr>
                        <th>Overseer:</th>
                        <td>
                            {{ meeting.overseer.full_name }}<br>
                            <a v-if="meeting.overseer.phone" :href="'tel:'+meeting.overseer.phone">{{ meeting.overseer.phone }}</a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </view-content>

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ServiceSchedule',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      meetings: state => state.schedules.field_meetings
    })
  },
  created () {
    this.$store.commit('BACKGROUND_LOADING', true)
    this.$store.dispatch('GET_FIELD_MEETINGS').then(() => {
      this.$store.commit('BACKGROUND_LOADING', false)
    })
  }
}
</script>

<style lang="less">

    .table {
        margin-bottom: 0;
    }

</style>
