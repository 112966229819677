import Vue from 'vue'
import _ from 'lodash'

export const SET_SPECIAL_SERVICE_GROUPS = (state, data) => {
  if (!data.id) state.locations = data
  else {
    const key = state.locations.findIndex(row => row.id === data.id)
    if (key >= 0) Vue.set(state.locations, key, data)
    else state.locations.unshift(data)
  }
}

export const DELETE_SPECIAL_SERVICE_GROUP = (state, data) => {
  const id = _.findIndex(state.locations, (location) => {
    return location.id === data.id
  })
  Vue.delete(state.locations, id)
}
