<template>

  <view-content>

    <template v-slot:header>
      {{ $t('labels.maps.title', { name: profile ? profile.first_name : '' }) }}
    </template>

    <template v-slot:actions>
      <action :primary="true" @click="$store.commit('SHOW_ALERTS')" class="mr-2 mb-2" :icon="['fal', 'bell']" v-if="notifications">
        {{ $t('actions.action_resource', { action: $t('actions.show'), resource: $tc('resources.notification', notifications) })}}
      </action>
      <action :secondary="true" @click="loadTour" class="mr-2">
        {{ $t('actions.action_resource', { action: $t('actions.take'), resource: $tc('resources.tour', 1) })}}
      </action>
    </template>

    <prompt-privacy />
    <prompt-install />
    <prompt-report />
    <prompt-group-change />
    <prompt-banner />

    <div class="notification is-warning" v-if="false && !this.$store.state.core.push_notifications" @click="$root.subscribeUser()">
      <div v-html="$t('notifications.push')"></div>
    </div>

    <SubscribePrompt v-if="['elder', 'servant'].includes(profile.appointment) && !profileSubscribed"
                     @subscribed="subscribed"
    />
    <prompt-online-meeting />
    <prompt-public-talk v-if="false" />
    <prompt-field-group />
    <prompt-cart />
    <prompt-subscribed-topic />

    <div class="flex-box justify-content-center" v-if="orderedMaps && orderedMaps.length">
      <router-link v-for="map in orderedMaps" :to="'/map/'+map.number" :key="map.id" class="button btn-box" :class="mapClass(map)">
        {{ map.number }}
      </router-link>
    </div>
  </view-content>

</template>

<script>
import { mapState } from 'vuex'
import { get } from '@/composables/serverInterface'
import { Sec } from '@/mixins/sec'
import SubscribePrompt from '@/components/dashboard/prompts/SubscribePrompt.vue'

export default {
  name: 'Dashboard',
  components: { SubscribePrompt },
  data () {
    return {
      profileSubscribed: true
    }
  },
  created () {
    this.$store.dispatch('GET_CONG_SETTINGS')
    this.$store.dispatch('GET_ALERTS')
    const start = new Sec('2024-08-20')
    const end = new Sec('2024-09-03')
    if (['elder', 'servant'].includes(this.profile.appointment) && new Sec().isBetween(start, end)) {
      get('profile-subscribed').then(res => {
        this.profileSubscribed = res.data
      })
    }
  },
  computed: {
    ...mapState({
      cong: state => state.settings.cong,
      profile: state => state.profile,
      maps: state => state.maps.maps,
      alerts: state => state.dashboard.alerts,
      error: state => state.core.error,
      loading: state => state.core.loading,
      remoteCache: state => state.core.remoteCache,
      localCache: state => state.core.localCache,
      nextGroupActive: state => state.dashboard.nextGroupActive,
      nextCartActive: state => state.dashboard.nextCartActive,
      publicTalkActive: state => state.dashboard.publicTalkActive,
      bannerActive: state => state.dashboard.bannerActive,
      onlineMeetingActive: state => state.dashboard.onlineMeetingActive,
      subscribedTopicsActive: state => state.dashboard.subscribedTopicsActive,
      subscribePromptActive: state => state.dashboard.subscribePromptActive
    }),
    orderedMaps () {
      return this._.orderBy(this.maps, ['order'])
    },
    notifications () {
      let count = 0
      if (!this.nextGroupActive) count++
      if (!this.nextCartActive) count++
      if (!this.publicTalkActive) count++
      if (!this.bannerActive) count++
      if (!this.onlineMeetingActive) count++
      if (!this.subscribedTopicsActive) count++
      if (!this.subscribePromptActive) count++
      return count
    }
  },
  methods: {
    mapClass (map) {
      if (map.type_id === 2) return 'business'
      if (map.type_id === 3) return 'rural'
      if (map.type_id === 4) return 'high-density'
      if (map.type_id === 5) return 'foreign'
      return ''
    },
    dismissAlert (alert) {
      this.$store.dispatch('HIDE_ALERT', { alert: alert })
    },
    loadTour () {
      this.$tours['welcome-tour'].start()
    },
    subscribed (val) {
      this.profileSubscribed = val
    }
  }
}
</script>

<style lang="less">

.flex-box {
  display: flex;
  flex-wrap: wrap;
}
.menu {
  text-align: left;
  margin-top: 20px;
}

</style>
