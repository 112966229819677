<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      Special Service Groups
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="permits['settings.special_service_groups.add']" @click="addSpecialLocation">
        Add Location
      </action>
    </template>

    <table class="table is-fullwidth align-middle">
      <tr v-for="location in locations" :key="'location' + location.id">
        <td>
          <span>{{ location.label }} - <i>{{ location.short_date }}</i></span><br>
          <small>Overseer: {{ location.overseer ? location.overseer.full_name : '' }}</small>
        </td>
        <td class="tools t2">
          <button @click="editSpecialLocation(location)" class="btn btn-info btn-round mr-1" v-if="permits['settings.special_service_groups.edit']">
            <fa-icon :icon="['fal', 'pencil']" />
          </button>
          <button @click="promptDeleteSpecialLocation(location)" class="btn btn-danger btn-round" v-if="permits['settings.special_service_groups.delete']">
            <fa-icon :icon="['fal', 'trash']" />
          </button>
        </td>
      </tr>
    </table>

    <portal to="modals">
    <modal :submitting="submitting" :header="'Special Service Location Form'" :active.sync="specialLocationFormActive" @submit="submitSpecialLocation()" :validated="specialLocationValid">
      <input-text :label="'Name'" v-model="location.label" />
      <datetimefield :label="'Meeting Date & Time'" v-model="location.meeting_date"></datetimefield>
      <input-toggle class="mb-3" :label="'Offline/Online'" v-model="location.online" />
      <input-text v-if="location.online" :label="'Meeting URL'" v-model="location.location" />
      <input-text v-if="!location.online" :label="'Location Address'" v-model="location.location" />
      <input-text v-if="!location.online" :label="'Coordinates'" v-model="location.coordinates" :help="'Enter coordinates if you wish this group to contain a link to Google Maps.'" />
      <tuxedo-select
        :label="'Overseer'"
        name="overseer"
        :options="sortedUsers"
        v-model="location.overseer.id"
        insistLabel="last_first"
        inputClass="bg-gray-100"
        insistReturn="id"
      />

      <checkboxfield :label="'Specific to Field Service Groups?'" v-model="location.groups" :options="groupOptions"></checkboxfield>
      <checkboxfield :label="'Replaces Standing Field Service Arrangement?'" v-model="location.replaces" :options="fsgOptions"></checkboxfield>
      <checkboxfield :label="'SMS all Applicable Publishers?'" v-model="location.notify" :options="{1:'Yes'}"></checkboxfield>
    </modal>

    <delete
      :submitting="submitting"
      :header="'Delete Field Service Location'"
      :active.sync="specialLocationDeleteActive"
      :message="'Are you sure you wish to delete this field service location?'"
      @submit="confirmDeleteSpecialLocation()"
    ></delete>
    </portal>

  </view-content>

</template>

<script>
import { get } from '../../../mixins/api'
import { assign } from '../../../mixins/storeMutations'
import { resetData, fillObject } from '../../../mixins/helpers'
import { mapState } from 'vuex'

export default {
  name: 'SpecialServiceGroups',
  mixins: [get, assign, resetData, fillObject],
  data () {
    return {
      location: {
        id: null,
        label: null,
        meeting_date: null,
        online: null,
        location: null,
        coordinates: null,
        overseer: {
          id: null
        },
        groups: [],
        replaces: [],
        notify: null
      },
      specialLocationFormActive: false,
      specialLocationDeleteActive: false,
      submitting: false,
      loading: true,
      error: false
    }
  },
  computed: {
    ...mapState({
      permits: state => state.core.permits,
      users: state => state.users,
      groups: state => state.groups,
      locations: state => state.specialServiceGroups.locations,
      fsgs: state => state.fieldServiceGroups
    }),
    sortedUsers () {
      return this._.orderBy(this.users, ['last_name', 'first_name'])
    },
    specialLocationValid () {
      if (
        this.location.label &&
                    this.location.meeting_date &&
                    this.location.location &&
                    this.location.overseer.id
      ) return true
      return false
    },
    groupOptions () {
      const groups = {}
      this.groups.forEach((group) => {
        this.$set(groups, group.id, group.name)
      })
      return groups
    },
    fsgOptions () {
      const locations = {}
      this.fsgs.forEach((fsg) => {
        this.$set(locations, fsg.id, fsg.label)
      })
      return locations
    }
  },
  created () {
    this.get('groups').then(res => {
      this.assign('groups', res)
    })
    this.get('field-service-groups').then(res => {
      this.assign('fieldServiceGroups', res)
    })
    this.$store.dispatch('GET_SPECIAL_SERVICE_GROUPS').then(() => {
      this.$store.commit('BACKGROUND_LOADING', false)
    })
  },
  methods: {
    addSpecialLocation () {
      this.resetData('location')
      this.specialLocationFormActive = true
    },
    editSpecialLocation (location) {
      this.resetData('location')
      this.fillObject(this.location, location)
      this.specialLocationFormActive = true
    },
    submitSpecialLocation () {
      this.submitting = true
      this.$store.dispatch('POST_SPECIAL_SERVICE_GROUP', this.location).then(() => {
        this.submitting = false
        this.specialLocationFormActive = false
      })
    },
    promptDeleteSpecialLocation (location) {
      this.$root.copyData(location, this.location)
      this.specialLocationDeleteActive = true
    },
    confirmDeleteSpecialLocation () {
      this.submitting = true
      this.$store.dispatch('DELETE_SPECIAL_SERVICE_GROUP', this.location).then(() => {
        this.submitting = false
        this.specialLocationDeleteActive = false
      })
    }
  }
}
</script>

<style>
</style>
