<template>

  <div v-if="assignment">
    <section class="assignment" :class="assignment.base_type" v-if="
      assignment.extent === 'everyone' ||
      (assignment.extent === 'elders' && profile.appointment === 'elder') ||
      (assignment.extent === 'appointed' && (profile.appointment === 'servant' || profile.appointment === 'elder')) ||
      (assignment.extent === 'pioneers_elders' && (profile.appointment === 'elder' || profile.pioneer === 1)) ||
      (assignment.extent === 'pioneers' && profile.pioneer === 1) ||
      !assignment.extent
      ">

      <card :start="start" :end="end" :date="date" :time="time" :location="location" :online="online"
            :color="assignment.base_type" :assistant="assistant" class="mb-5"
            :actionSlot="assignment.base_type === 'group'"
      >
        <template v-slot:header>
          <span v-if="assignment.base_type === 'custom'">{{ assignment.label }}</span>
          <span v-else>{{ $t('labels.appointments.'+assignment.base_type) }}</span>
        </template>
        <template v-slot:content>
          <span v-if="assignment.theme">{{ $t('labels.theme', { theme: assignment.theme }) }}<br></span>
          <span v-if="assignment.base_type === 'assembly'">{{ $t('labels.theme', { theme: assignment.label }) }}</span>
          <span v-if="assignment.base_type === 'attendant'">{{ $t('labels.attendant_responsibility', { responsibility: assignment.responsibility }) }}</span>
          <span v-if="assignment.base_type === 'meeting'" v-html="talkTitle"></span>
          <span v-if="assignment.base_type === 'group'" v-html="assignment.label"></span>
          <span v-if="assignment.speaker">
            <span v-html="assignment.speaker"></span><br>
          </span>
          <span v-if="assignment.base_type === 'talk'">
            <span v-html="assignment.notes"></span><br>
            <span>{{ $t('labels.cong_name', { congregation: assignment.congregation }) }}</span>
          </span>
          <span v-if="assignment.base_type === 'convention'">
            <span>{{ assignment.label }}</span>
            </span>
          <span v-if="assignment.base_type === 'annual_pioneer'">
            <span>{{ $t('labels.theme', { theme: assignment.label }) }}</span>
          </span>

        </template>
        <template v-slot:actions>
          <div v-if="assignment.base_type === 'group'">
            <action :primary="true" :small="true" @click="promptChangeAssignment(assignment.id)">{{ $t('actions.change') }}</action>
          </div>
        </template>
      </card>

      <modal :submitting="submitting" :header="'Request Assignment Change'" :active.sync="changeAssignmentFormActive" @submit="submitRequestChange()" :validated="true">
        <tuxedo-select
          :label="'New Overseer'"
          name="overseer"
          :options="overseers"
          v-model="specialAssignment.overseer"
          insistLabel="last_first"
          inputClass="bg-gray-100"
          insistReturn="id"
        />
      </modal>

    </section>
  </div>

</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['assignment'],
  data () {
    return {
      specialAssignment: {
        id: null,
        overseer: null
      },
      changeAssignmentFormActive: false,
      cancelAssignmentKey: null,
      cancelAssignmentActive: false,
      submitting: false
    }
  },
  methods: {
    promptChangeAssignment (id) {
      this.specialAssignment.id = id
      this.changeAssignmentFormActive = true
    },
    submitRequestChange () {
      this.submitting = true
      this.$store.dispatch('REQUEST_CHANGE_ASSIGNMENT', { assignment: this.specialAssignment }).then(() => {
        this.submitting = false
        this.changeAssignmentFormActive = false
      })
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      users: state => state.users
    }),
    start () { return new Date(this.assignment.base_date) },
    end () { return new Date(this.assignment.end_date) },
    date () {
      const arr = ['co', 'convention']
      if (arr.includes(this.assignment.base_type)) return 'range'
      return true
    },
    time () {
      const incl = ['assembly', 'annual_pioneer', 'kmse']
      const excl = ['co', 'convention']
      if (incl.includes(this.assignment.base_type)) return 'range'
      if (excl.includes(this.assignment.base_type)) return false
      return true
    },
    location () {
      if (this.assignment.online) return false
      return this.assignment.location
    },
    online () {
      if (this.assignment.online) return this.assignment.location
      return false
    },
    assistant () {
      if (this.assignment.helper_id) return this._.find(this.users, u => parseInt(u.id) === parseInt(this.assignment.helper_id))
      return false
    },
    type () {
      switch (this.assignment.base_type) {
        case 'Assembly': return 'assembly'
        case 'Circuit Overseer Visit': return 'co'
        case 'Special Talk': return 'special'
        case 'Memorial': return 'memorial'
        case 'Convention': return 'convention'
        case 'Circuit Pioneer Day': return 'pioneer'
        case 'Congregation Working Bee': return 'bee'
        case 'Kingdom Ministry School (Elders)': return 'kingdom-school'
        case 'Annual Pioneer Meeting': return 'pioneer'
      }
      return ''
    },
    overseers () {
      const pubs = this.users.filter(pub => {
        if (pub.active === 1 && (['servant', 'elder'].includes(pub.appointment) || pub.role === 12)) return pub
      })
      return this._.orderBy(pubs, ['last_name'])
    },
    talkTitle () {
      const parts = this.assignment.part.split('.')
      if (['first', 'second', 'third', 'fourth'].includes(parts[1])) {
        return this.assignment.title + ' <i>(' + parts[1] + ')</i>'
      }
      const arr = ['watchtower.discussion', 'living.study', 'ministry.first', 'ministry.second', 'ministry.third', 'ministry.study']
      if (arr.includes(this.assignment.part) && this.assignment.helper_id === this.profile.id) {
        return this.assignment.reader
      }
      return this.assignment.title
    }
  }
}
</script>
