<template>

    <view-content>

      <template v-slot:header>
        {{ $tc('resources.setting', 0) }}
      </template>

      <sub-menu class="mt-3">
        <sm-item>
          <router-link to="/settings/my-profile/" @click.native="callbackTour()" id="my-profile">{{ $t('nav.my_profile.label') }}</router-link>
        </sm-item>
        <sm-item v-if="sid === 1 || sid === 40070">
          <router-link to="/settings/translations/">{{ $t('nav.translations.label') }}</router-link>
        </sm-item>
        <sm-item v-if="permits['forums.features']">
          <router-link to="/forum/topics/">{{ $t('nav.feature_requests.label') }}</router-link>
        </sm-item>
        <sm-item>
          <a @click="resetServiceApp">{{ $t('nav.reload.label') }}</a>
        </sm-item>
      </sub-menu>

      <sub-menu :label="$t('labels.congregation_settings')" v-if="displayCongregationSettings">
        <sm-item v-if="permits['settings.cong.edit']">
          <a @click="editCongSettings()">{{ $t('nav.congregation_details.label') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.cong.edit']">
          <a @click="meetingFormActive = true" >{{ $t('nav.meeting_settings.label') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.account']">
          <router-link to="/settings/credits/" v-if="permits['settings.account']">
            Account <small>(balance: <span v-html="cong.balance"></span>)</small>
          </router-link>
        </sm-item>
        <sm-item v-if="permits['settings.roles']">
          <router-link to="/settings/user-roles/">{{ $t('nav.roles.label') }}</router-link>
        </sm-item>
        <sm-item v-if="permits['settings.events']">
          <router-link to="/settings/events/" >{{ $t('nav.events.label') }}</router-link>
        </sm-item>
      </sub-menu>

      <sub-menu :label="$t('labels.publisher_settings')">
        <sm-item v-if="permits['settings.users']">
          <router-link to="/settings/users/">{{ $t('nav.publishers.label') }}</router-link>
        </sm-item>
        <sm-item v-if="this.cong.modules.meeting_schedule.on && permits['settings.users.qualifications']">
          <router-link to="/settings/qualifications/">{{ $t('nav.qualifications.label') }}</router-link>
        </sm-item>
        <sm-item v-if="this.cong.modules.field_service_reports.on">
          <router-link to="/settings/reports/">{{ $t('nav.service_reports.label') }}</router-link>
        </sm-item>
        <sm-item v-if="this.cong.modules.field_service_reports.on && permits['settings.reports.totals']">
          <router-link to="/settings/report-totals/">{{ $t('nav.service_totals.label') }}</router-link>
        </sm-item>
        <sm-item><router-link to="/settings/away/">{{ $t('nav.away.label') }}</router-link></sm-item>
      </sub-menu>

      <sub-menu :label="$t('labels.field_service_settings')" v-if="displayFieldServiceSettings">
        <sm-item v-if="permits['settings.groups']">
          <router-link to="/settings/groups/">{{ $t('nav.service_groups.label') }}</router-link>
        </sm-item>
        <sm-item v-if="permits['settings.field_service_locations']">
          <router-link to="/settings/field-service-locations/">{{ $t('nav.service_locations.label') }}</router-link>
        </sm-item>
        <sm-item v-if="permits['settings.special_service_groups']">
          <router-link to="/settings/special-service-groups/">{{ $t('nav.special_groups.label') }}</router-link>
        </sm-item>
        <sm-item v-if="permits['settings.rotation_groups']">
          <router-link to="/settings/rotation-groups/">{{ $t('nav.service_rotations.label') }}</router-link>
        </sm-item>
      </sub-menu>

      <sub-menu :label="$t('labels.public_witnessing_settings')" v-if="displayPublicWitnessingSettings">
        <sm-item v-if="permits['settings.cart_locations']">
          <router-link to="/settings/carts/">{{ $t('nav.public_locations.label') }}</router-link>
        </sm-item>
        <sm-item v-if="permits['settings.cart_rotations']">
          <router-link to="/settings/cart-rotations/">{{ $t('nav.public_rotations.label') }}</router-link>
        </sm-item>
        <sm-item v-if="permits['settings.cart_locations']">
          <router-link to="/settings/cart-bookings/">{{ $t('nav.public_bookings.label') }}</router-link>
        </sm-item>
      </sub-menu>

      <sub-menu :label="$tc('resources.meeting', 0)" v-if="displayMeetingSettings">
        <sm-item v-if="this.cong.modules.meeting_attendance.on && permits['settings.meetings.attendance']">
          <router-link to="/settings/meeting-attendance/">{{ $t('nav.meeting_attendance.label') }}</router-link>
        </sm-item>
        <sm-item v-if="this.cong.modules.attendants_schedule.on && permits['settings.meetings.attendants']">
          <router-link to="/settings/attendants-scheduler/">{{ $t('nav.attendants_scheduler.label') }}</router-link>
        </sm-item>
        <sm-item v-if="this.cong.modules.meeting_schedule.on && permits['settings.meetings.public']">
          <router-link to="/settings/speaker-directory/">{{ $t('nav.speaker_directory.label') }}</router-link>
        </sm-item>
        <sm-item v-if="this.cong.modules.meeting_schedule.on && permits['settings.meetings.public']">
          <router-link to="/settings/public-scheduler/">{{ $t('nav.public_scheduler.label') }}</router-link>
        </sm-item>
      </sub-menu>

      <sub-menu :label="$t('labels.map_manager')" v-if="permits['settings.maps']">
        <sm-item v-if="false">
          <router-link to="/settings/map-builder/">Map Builder</router-link>
        </sm-item>
        <sm-item>
          <router-link to="/settings/maps/">{{ $t('nav.maps.label') }}</router-link>
        </sm-item>
        <sm-item>
          <router-link to="/settings/assign/">{{ $t('nav.assign_maps.label') }}</router-link>
        </sm-item>
      </sub-menu>

      <sub-menu :label="$tc('resources.notification', 0)" v-if="displayNotificationSettings">
        <sm-item v-if="permits['settings.cong.edit']">
          <router-link to="/settings/messaging-settings/" id="automatic-messaging" @click.native="callbackTour()">{{ $t('nav.automatic_messaging.label') }}</router-link>
        </sm-item>
        <sm-item v-if="permits['settings.cong.edit']">
          <a @click="editBannerNotification">{{ $t('nav.banner_notification.label') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.send_messages']">
          <router-link to="/settings/messaging/">{{ $t('nav.send_messages.label') }}</router-link>
        </sm-item>
      </sub-menu>

      <sub-menu :label="$t('labels.printables_exports')">
        <sm-item v-if="this.cong.modules.attendants_schedule.on && permits['settings.print.attendants_schedule']">
          <a @click="openAttendantsSchedule" id="print-attendants-schedule">{{ $t('labels.attendants_schedule') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.print.co_schedule']">
          <a :href="'https://srvc.app/api/reports/co-schedule?api_token='+this.$store.state.core.token" target="_blank">
            {{ $t('labels.circuit_overseer_schedule') }}
          </a>
        </sm-item>
        <sm-item v-if="permits['settings.print.cong_report']">
          <a @click="printCongregationReport">{{ $t('labels.congregation_report') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.print.groups_elder']">
          <a :href="'https://srvc.app/api/reports/publisher-contact-list?api_token='+this.$store.state.core.token" target="_blank">
            {{ $t('labels.emergency_contact_list') }}
          </a>
        </sm-item>
        <sm-item v-if="permits['settings.print.groups']">
          <a @click="printGroupList(false)">{{ $t('labels.group_list') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.print.groups_elder']">
          <a @click="printGroupList(true)">{{ $t('labels.group_list_elders') }}</a>
        </sm-item>
        <sm-item v-if="this.cong.modules.meeting_schedule.on && permits['settings.print.s_140']">
          <a @click="openPrintCLMSchedule">{{ $t('labels.clm_schedule') }}</a>
        </sm-item>
        <sm-item v-if="this.cong.modules.meeting_schedule.on && permits['settings.print.s_89']">
          <a @click="printAssignmentSlips">{{ $t('labels.clm_assignments') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.print.dnc_list']">
          <router-link to="/settings/print-dncs/">{{ $t('labels.print_dncs') }}</router-link>
        </sm-item>
        <sm-item v-if="this.cong.modules.meeting_schedule.on && permits['settings.print.public_schedule']">
          <a @click="printPublicSchedule">{{ $t('labels.public_meeting_schedule') }}</a>
        </sm-item>
        <sm-item v-if="this.cong.modules.meeting_schedule.on && permits['settings.print.public_talk_history']">
          <a @click="printPublicTalkHistory">{{ $t('labels.public_talk_history') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.print.groups_elder']">
          <a @click="generatePublisherReports">{{ $t('labels.publisher_record_cards') }}</a>
        </sm-item>
        <sm-item v-if="permits['settings.print.s_13']">
          <a @click="generateTerritoryRecords" target="_blank">{{ $t('labels.territory_assignments') }}</a>
        </sm-item>
      </sub-menu>

      <sub-menu :label="$t('labels.mailables')" v-if="permits['settings.mail']">
        <mail-clm-to-chairman />
      </sub-menu>

      <portal to="modals">

        <CongregationSettingsForm :active="congFormActive" @close="congFormActive = false" />
        <MeetingSettingsForm :active="meetingFormActive" @close="meetingFormActive = false" />
        <PrintAttendantsScheduleForm :active="printAttendantsScheduleFormActive" @close="printAttendantsScheduleFormActive = false" />
        <PrintLifeAndMinistryScheduleForm :active="printLifeAndMinistryScheduleActive" @close="printLifeAndMinistryScheduleActive = false" />
        <BannerNotificationForm :active="bannerNotificationFormActive" @close="bannerNotificationFormActive = false" />

      <progress-modal :active="progressActive" :progress="progress" :message="'Generating cards, this may take a few minutes.'" />
      </portal>

    </view-content>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { clearCache, downloadXLSX } from '@/composables/dataInterface'
import { get } from '@/composables/serverInterface'
import BannerNotificationForm from '@/components/forms/BannerNotificationForm.vue'
import PrintLifeAndMinistryScheduleForm from '@/components/forms/PrintLifeAndMinistryScheduleForm.vue'
import PrintAttendantsScheduleForm from '@/components/forms/PrintAttendantsScheduleForm.vue'
import MeetingSettingsForm from '@/components/forms/MeetingSettingsForm.vue'
import CongregationSettingsForm from '@/components/forms/CongregationSettingsForm.vue'

export default {
  name: 'Settings',
  components: { CongregationSettingsForm, MeetingSettingsForm, PrintAttendantsScheduleForm, PrintLifeAndMinistryScheduleForm, BannerNotificationForm },
  data () {
    return {
      loading: true,
      error: false,
      submitting: false,
      congFormActive: false,
      meetingFormActive: false,
      cartFormActive: false,
      printAttendantsScheduleFormActive: false,
      printLifeAndMinistryScheduleActive: false,
      bannerNotificationFormActive: false,
      address_options: [
        { id: 0, label: 'Delete' },
        { id: 1, label: 'Email' }
      ],
      publishers: {},
      reporting: null,
      days: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat'
      },
      history: {
        simple: 'Simple',
        detailed: 'Detailed'
      },
      CLMPrintSize: 0,
      progressActive: false,
      progress: 0
    }
  },
  computed: {
    ...mapState({
      cong: state => state.settings.cong,
      sid: state => state.core.sid,
      permits: state => state.core.permits,
      timezones: state => state.timezones,
      langs: state => state.core.langs
    }),
    displayCongregationSettings () {
      return this.permits['settings.cong.edit'] || this.permits['settings.account'] ||
        this.permits['settings.account'] || this.permits['settings.roles'] ||
        this.permits['settings.events'] || this.permits['settings.cong.visitors']
    },
    displayFieldServiceSettings () {
      return this.permits['settings.groups'] ||
        this.permits['settings.field_service_locations'] || this.permits['settings.special_service_groups'] ||
        this.permits['settings.rotation_groups']
    },
    displayPublicWitnessingSettings () {
      return this.permits['settings.cart_locations'] ||
        this.permits['settings.cart_rotations'] || this.permits['settings.cart_locations']
    },
    displayMeetingSettings () {
      return (this.cong.modules.meeting_schedule.on || this.cong.modules.attendants_schedule.on) &&
      (this.permits['settings.meetings.attendance'] || this.permits['settings.meetings.attendants'] || this.permits['settings.meetings.public'])
    },
    displayNotificationSettings () {
      return this.permits['settings.send_messages'] || this.permits['settings.cong.edit']
    }
  },
  created () {
    this.$emit('update:loading', false)
  },
  methods: {
    editCongSettings () {
      this.congFormActive = true
    },
    editBannerNotification () {
      this.bannerNotificationFormActive = true
    },
    resetServiceApp () {
      const token = this.$store.state.core.token
      clearCache()
      window.sessionStorage.removeItem('vuex')
      window.localStorage.removeItem('vuex')
      this.$router.push('/?t=' + token)
      window.location.reload(true)
    },
    generatePublisherReports () {
      this.progressActive = true
      this.progress = 0
      this.getPublisherReportProgress()
      this.$store.dispatch('GENERATE_PUBLISHER_REPORTS')
    },
    getPublisherReportProgress () {
      setTimeout(() => {
        axios.get('api/reports/publisher-reports/progress')
          .then((response) => {
            if (response.data.data && response.data.data.total) {
              const total = response.data.data.total
              const completed = response.data.data.completed
              this.progress = parseInt((100 / total) * completed)
              if (this.progress < 100) this.getPublisherReportProgress()
            } else {
              this.progressActive = false
              this.progress = 100
              this.$store.dispatch('DOWNLOAD_PDF', 'https://srvc.app/api/reports/publisher-reports/download')
            }
          })
      }, 3000)
    },
    generateTerritoryRecords () {
      this.$store.commit('BACKGROUND_LOADING', true)
      const url = 'https://srvc.app/api/reports/s13'
      // const url = 'http://localhost:8000/api/reports/s13'
      this.$store.dispatch('DOWNLOAD_PDF', url).then(() => {
        this.$store.commit('BACKGROUND_LOADING', false)
      })
    },
    getTerritoryRecordsProgress () {
      setTimeout(() => {
        axios.get('api/reports/s13/progress')
          .then((response) => {
            if (response.data.data && response.data.data.total) {
              const total = response.data.data.total
              const completed = response.data.data.completed
              this.progress = parseInt((100 / total) * completed)
              if (this.progress < 100) this.getTerritoryRecordsProgress()
            } else {
              this.progressActive = false
              this.progress = 100
              this.$store.dispatch('DOWNLOAD_PDF', 'https://srvc.app/api/reports/s13/download')
            }
          })
      }, 3000)
    },
    openAttendantsSchedule () {
      this.printAttendantsScheduleFormActive = true
      if (this.$tours['attendants-schedule-tour'].currentStep === 20) {
        this.$tours['attendants-schedule-tour'].currentStep++
      }
    },
    openPrintCLMSchedule () {
      this.printLifeAndMinistryScheduleActive = true
    },
    printPublicSchedule () {
      this.$store.commit('BACKGROUND_LOADING', true)
      this.$store.dispatch('GENERATE_PUBLIC_SCHEDULE').then(() => {
        this.$store.commit('BACKGROUND_LOADING', false)
      })
    },
    printPublicTalkHistory () {
      this.$store.commit('BACKGROUND_LOADING', true)
      const url = 'https://srvc.app/api/reports/public-talk-history/download'
      this.$store.dispatch('DOWNLOAD_XLSX', url).then(() => {
        this.$store.commit('BACKGROUND_LOADING', false)
      })
    },
    printAssignmentSlips () {
      this.$store.commit('BACKGROUND_LOADING', true)
      const url = 'https://srvc.app/api/reports/s89'
      this.$store.dispatch('DOWNLOAD_PDF', url).then(() => {
        this.$store.commit('BACKGROUND_LOADING', false)
      })
    },
    printCongregationReport () {
      get(['reports', 'cong-report'], {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      }).then(res => { downloadXLSX(res) })
    },
    printGroupList (elders) {
      this.$store.commit('BACKGROUND_LOADING', true)
      this.$store.dispatch('GENERATE_GROUP_LIST', { elders: elders }).then(() => {
        this.$store.commit('BACKGROUND_LOADING', false)
      })
    },
    callbackTour () {
      if (this.$tours['welcome-tour'].currentStep === 15) {
        this.$tours['welcome-tour'].currentStep++
      }
      if (this.$tours['attendants-schedule-tour'].currentStep === 17) {
        this.$tours['attendants-schedule-tour'].currentStep++
      }
    }
  }
}
</script>

<style lang="less">

    .menu {
        text-align: left;
        margin-top: 20px;
    }

</style>
