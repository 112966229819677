import { defineStore } from 'pinia'
import { useCollectionsStore } from './collections'
import { remove } from 'lodash'

export const useProfileStore = defineStore({
  id: 'profile',
  persist: true,
  state: () => ({
    user: {},
    darkMode: false,
    authenticated: false,
    lastAuthenticated: null,
    permits: {},
    appOnline: true,
    offlinePayloads: []
  }),
  getters: {},
  actions: {
    sync () {
      const store = useCollectionsStore()

      const promises = []
      promises.push(store.get('countries'))
      promises.push(store.get('regions'))
      promises.push(store.get('sharedCarts'))
      // promises.push(store.get('permissions'))

      for (const payload of this.offlinePayloads) {
        promises.push(
          store.post(payload.uri, payload.payload)
            .then(() => {
              console.log(payload.id)
              remove(this.offlinePayloads, (p) => p.id === payload.id)
            })
            .catch(err => {
              console.log(err)
            })
        )
      }

      return Promise.all(promises)
    }
  }
})
