<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      {{ $tc('resources.cart_rotation', 0) }}
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="permits['settings.cart_rotations.add']" @click="create">
        {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.cart_rotation', 1) }) }}
      </action>
    </template>

    <table class="table is-fullwidth align-middle">
      <tr v-for="rotation in rotations" :key="'rot' + rotation.id">
        <td>
          <span>{{ find(carts, 'id', rotation.cart_id, 'location') }}</span>
          <small class="ml-2" v-if="!rotation.active">(Paused)</small><br>
          <small><time-only :time="rotation.start_time" />, {{ rotation.frequency === 'month' ? weeks[rotation.week_of_month || 0] + ', ' : '' }} {{ hours[rotation.minutes] }} hours {{ rotation.frequency }}ly, {{ rotation.users.length }} publishers</small><br>
          <small>{{ $tc('messages.rotation_combinations', rotation.combinations, { count: rotation.combinations }) }}</small><br>
          <small>Booked up to {{ date(rotation.current_date).format('l jS M, Y') }} </small>
        </td>
        <td class="tools t3">
          <button @click="toggleActive(rotation)"
                  class="btn btn-round mr-1"
                  :class="!rotation.active ? 'btn-success' : 'btn-warning'"
                  v-if="permits['settings.cart_rotations.edit']">
            <fa-icon v-if="toggleSubmitting" :icon="['fal', 'spinner-third']" spin />
            <fa-icon v-else-if="!rotation.active" :icon="['fas', 'play']" />
            <fa-icon v-else :icon="['fas', 'pause']" />
          </button>
          <button @click="update(rotation)" class="btn btn-info btn-round mr-1" v-if="permits['settings.cart_rotations.edit']">
            <fa-icon :icon="['fal', 'pencil']" />
          </button>
          <button @click="promptDestroy(rotation)" class="btn btn-danger btn-round" v-if="permits['settings.cart_rotations.delete']">
            <fa-icon :icon="['fal', 'trash']" />
          </button>
        </td>
      </tr>
    </table>

    <modal :submitting="submitting" :header="'Rotation Form'" :active.sync="rotationFormActive" @submit="submit" :validated="resourceValid">
      <template v-slot:default="{ error }">
        <tuxedo-select
          :label="'Cart Location'"
          name="cart_id"
          :options="carts"
          v-model="resource.cart_id"
          insistLabel="location"
          inputClass="bg-gray-100"
          insistReturn="id"
        />
        <input-slider :label="'Frequency'" v-model="resource.frequency" :options="frequency" />
        <tuxedo-date :name="'current_date'" :label="'Starting On'" v-model="resource.current_date"
                     :inputClass="'bg-gray-100'" v-if="resource.frequency === 'fortnight'" :insistFormat="'isoDate'" />
        <input-slider :label="'Week of the Month'" v-model="resource.week_of_month" :options="weeks"
                     v-if="resource.frequency === 'month'" class="pb-1" />
        <input-slider :label="'Day of the Week'" v-model="resource.day_of_week" :options="days"
                     v-if="resource.frequency" class="pb-1" />
        <input-time :label="'Start Time'" :name="'start_time'" v-model="resource.start_time" :minute-step="5" :config="timeConfig" />
        <input-slider :label="'Hours'" v-model="resource.minutes" :options="hours" />
        <tuxedo-select :name="'users'" :label="$tc('resources.publisher', 0)" v-model="resource.users" :multi="true"
                       :options="cartApprovedUsers" :insistLabel="'last_first'" :insistReturn="'id'"
                       :inputClass="'bg-gray-100'" :error="error"  />
        <input-slider :label="'Number of Publishers to Assign'" v-model="resource.publisher_count" :options="{2:'2',3:'3'}" class="pb-1" />
      </template>
    </modal>

    <delete
      :submitting="submitting"
      :header="'Delete Rotation'"
      :active.sync="rotationDeleteActive"
      :message="'Are you sure you wish to delete this rotation?'"
      @submit="confirmDeleteRotation()"
    ></delete>
  </view-content>

</template>

<script>
import { mapState } from 'vuex'
import { get, put, post, destroy } from '../../../mixins/api'
import { assign, push, merge, forget } from '../../../mixins/storeMutations'
import { resourceValid, resetData, fillObject } from '../../../mixins/helpers'
import { Sec } from '@/mixins/sec'

export default {
  name: 'CartRotations',
  mixins: [get, put, post, destroy, assign, push, merge, forget, resourceValid, resetData, fillObject],
  data () {
    return {
      activeID: null,
      resource: {
        id: null,
        active: 0,
        cart_id: null,
        frequency: null,
        day_of_week: 0,
        week_of_month: null,
        start_time: null,
        minutes: 60,
        current_date: null,
        users: null,
        publisher_count: 2
      },
      requiredResourceFields: ['frequency', 'start_time', 'minutes', 'cart_id'],
      rotationFormActive: false,
      rotationDeleteActive: false,
      submitting: null,
      toggleSubmitting: false,
      loading: true,
      error: false,
      frequency: {
        week: 'Week',
        fortnight: 'Fortnight',
        month: 'Month'
      },
      days: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat'
      },
      weeks: {
        0: '1st Week',
        1: '2nd Week',
        2: '3rd Week',
        3: '4th Week',
        4: 'Last Week'
      },
      hours: {
        60: '1',
        90: '1½',
        120: '2',
        150: '2½',
        180: '3',
        210: '3½',
        240: '4'
      }
    }
  },
  computed: {
    ...mapState({
      carts: state => state.cartLocations,
      rotations: state => state.cartRotations,
      users: state => state.users,
      permits: state => state.core.permits,
      sid: state => state.core.sid
    }),
    cartApprovedUsers () {
      const users = this._.filter(this.users, u => u.cart === 1)
      return this._.orderBy(users, u => u.last_first)
    },
    timeConfig () {
      if (this.resource.cart_id) {
        const cart = this._.find(this.carts, ['id', parseInt(this.resource.cart_id)])
        if (cart) {
          return {
            noCalendar: true,
            dateFormat: 'H:i',
            enableTime: true,
            minuteIncrement: 30,
            minDate: cart.start_time,
            maxDate: cart.end_time
          }
        }
      }
      return {
        noCalendar: true,
        dateFormat: 'H:i',
        enableTime: true,
        minuteIncrement: 30
      }
    }
  },
  created () {
    this.get('cart-locations').then(res => {
      this.assign('cartLocations', res)
    })
    this.get('cart-rotations').then(res => {
      this.assign('cartRotations', res)
    })
  },
  methods: {
    date (time) {
      return new Sec(time)
    },
    create () {
      this.resetData('resource')
      this.rotationFormActive = true
    },
    update (r) {
      this.resetData('resource')
      this.fillObject(this.resource, r, 0)
      this.rotationFormActive = true
    },
    toggleActive (r) {
      this.toggleSubmitting = true
      this.resetData('resource')
      this.fillObject(this.resource, r, 0).then(() => {
        this.resource.active = !this.resource.active
        this.submit()
      })
    },
    submit () {
      if (this.resource.id) {
        this.put(['cart-rotations', this.resource.id], this.resource).then(res => {
          this.merge('cartRotations', res.data.data)
          this.rotationFormActive = false
          this.toggleSubmitting = false
        })
      } else {
        this.post(['cart-rotations'], this.resource).then(res => {
          this.push('cartRotations', res)
          this.rotationFormActive = false
          this.toggleSubmitting = false
        })
      }
    },
    promptDestroy (r) {
      this.activeID = r.id
      this.rotationDeleteActive = true
    },
    confirmDeleteRotation () {
      this.destroy(['cart-rotations', this.activeID]).then(() => {
        this.forget('cartRotations', this.activeID)
        this.rotationDeleteActive = false
      })
    }
  }
}
</script>
