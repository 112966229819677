import axios from 'axios'

export const GET_ROTATION_GROUPS = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/rotation-groups')
      .then(function (response) {
        commit('SET_ROTATION_GROUPS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_ROTATION_GROUP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    let method = 'post'
    let url = '/api/rotation-groups'
    if (payload.id > 0) {
      method = 'put'
      url = '/api/rotation-groups/' + payload.id
    }
    axios({ method: method, url: url, data: payload }).then(function (response) {
      commit('SET_ROTATION_GROUPS', response.data.data)
      resolve(response)
    })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_ROTATION_GROUP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/rotation-groups/' + payload.id)
      .then(function (response) {
        commit('DELETE_ROTATION_GROUP', payload)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
