<template>

  <view-content :back="{ name: 'Schedules' }">

    <template v-slot:header>
      {{ $tc('resources.attendant', 0) }}
    </template>

    <div class="card is-blue" v-for="(meeting, key) in schedule" :key="'sched' + key">
      <div class="card-content">
        <div class="date-header">
          <div class="date">
            <span>{{ showMonth(meeting.local_date) }}</span>
            <span>{{ showDay(meeting.local_date) }}</span>
          </div>
          <div class="header">
            {{ $t('labels.type.'+meeting.type) }}
          </div>
          <div class="float-right" v-if="permits['schedules.attendants.edit']">
            <button class="btn-info btn-round" @click="editAttendants(meeting)" :id="key === 0 ? 'first-meeting' : ''">
              <fa-icon :icon="['fal', 'pencil']" />
            </button>
          </div>
        </div>
        <table class="table is-narrow is-fullwidth no-border">
          <tr v-for="attendants in orderedAttendants(meeting.attendants)" :key="'att' + attendants.id">
            <th width="100px">{{ attendants.responsibility }}</th>
            <td v-if="attendants.user">{{ publisherName(attendants.user) }}</td>
          </tr>
        </table>
      </div>
    </div>

    <portal to="modals">
    <modal :submitting="submitting" :header="'Edit Attendants'" :active.sync="attendantsFormActive" @submit="submitAttendants">
      <input-toggle class="mb-3" :label="'Show All Publishers'" v-model="showAll" :help="'Choose from all publishers in your congregation'" />
      <div v-for="res in orderedResponsibilities" :key="res.id">
        <tuxedo-select
          :name="'attendants_' + res.id"
          :label="res.label"
          :options="optionalPubs1(res)"
          v-model="meeting.attendants[res.id]"
          insistLabel="last_first"
          inputClass="bg-gray-100"
          insistReturn="id"
        />
      </div>

    </modal>
    </portal>

  </view-content>

</template>

<script>
import { get } from '@/mixins/api'
import { assign } from '@/mixins/storeMutations'
import { mapState } from 'vuex'
import { fillObject } from '@/mixins/helpers'
import { Sec } from '@/mixins/sec'

export default {
  name: 'Attendants',
  mixins: [get, assign, fillObject],
  data () {
    return {
      showAll: false,
      meeting: {
        id: null,
        attendants: {}
      },
      submitting: false,
      loading: true,
      error: false
    }
  },
  computed: {
    ...mapState({
      permits: state => state.core.permits,
      schedule: state => state.attendants.schedule,
      users: state => state.users,
      responsibilities: state => state.attendantResponsibilities
    }),
    attendantsFormActive: {
      get: function () {
        return this.$store.state.schedules.attendantsFormActive
      },
      set: function () {
        this.$store.commit('CLOSE_ATTENDANTS_FORM')
      }
    },
    orderedResponsibilities () {
      return this._.orderBy(this.responsibilities, ['order'])
    },
    optionalPubs () {
      let users = this._.orderBy(this.users, ['last_first'])
      users = users.filter(user => {
        if (parseInt(user.active) === 0) return false
        if (this.showAll) return user
        // Baptised brother required
        if (parseInt(user.gender) === 2 || ['student', 'unbaptised'].includes(user.appointment)) return false
        return user
      })
      return users
    }

  },
  mounted () {
    this.get('attendant-responsibilities').then(res => {
      this.assign('attendantResponsibilities', res)
    })
    this.$store.commit('BACKGROUND_LOADING', true)
    this.$store.dispatch('GET_ATTENDANT_SCHEDULE').then(() => {
      this.$store.commit('BACKGROUND_LOADING', false)
    })
  },
  methods: {
    optionalPubs1 (responsibility) {
      let users = this._.orderBy(this.users, ['last_first'])
      users = users.filter(user => {
        if (this.showAll) return user
        return responsibility.attendants.includes(user.id)
      })
      return users
    },
    orderedAttendants (attendants) {
      return this._.orderBy(attendants, ['order'])
    },
    publisherName (id) {
      const user = this._.find(this.users, { id: id })
      if (user) return user.full_name
      else return null
    },
    editAttendants (meeting) {
      this.meeting.id = meeting.id
      this.meeting.attendants = {}
      this._.forEach(meeting.attendants, (a) => {
        this.meeting.attendants[a.id] = a.user
      })
      this.$store.commit('OPEN_ATTENDANTS_FORM')
      if (this.$tours['attendants-schedule-tour'].currentStep === 14) {
        this.$tours['attendants-schedule-tour'].currentStep++
      }
    },
    update (active) {
      console.log(active)
    },
    submitAttendants () {
      this.submitting = true
      this.$store.dispatch('SUBMIT_ATTENDANT_SCHEDULE', this.meeting).then(() => {
        this.submitting = false
        this.$store.commit('CLOSE_ATTENDANTS_FORM')
      })
    },
    showMonth (time) {
      return (new Sec(time)).format('M')
    },
    showDay (time) {
      return (new Sec(time)).format('jS')
    }
  }
}
</script>
